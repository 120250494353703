<template>
    <!-- Main Content -->
    <div class="hk-pg-wrapper hk-auth-wrapper" style="background-color: blanchedalmond; height: 100%;">
        <!-- /Main Content -->
        <slot></slot>
    </div>

</template>
  
<script>
  export default {
 
  }
</script>

<style>
@import "../assets/login/css/style.css";

body {
    height: 100vh;
}

#app {
    height: 100%;
}
</style>