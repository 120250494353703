<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <RouterView/> -->
  <!-- <div id="app"> -->
    <component :is="layout">
      <router-view/>
    </component>
  <!-- </div> -->
</template>

<script>
import AuthenticatedLayoutVue from './layouts/AuthenticatedLayout.vue'

export default {
  name: 'App',
  components: {
  }, 
  computed: {
    layout () {
      console.log(this.$route.meta.layout)
      return (this.$route.meta.layout || AuthenticatedLayoutVue)
    }
  },
}
</script>

<style>

</style>
