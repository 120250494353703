import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import AuthenticatedLayout from './layouts/AuthenticatedLayout'
import LoginLayout from './layouts/LoginLayout'

axios.defaults.baseURL = 'https://test.chat.cstuition.com.my/api/';
axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;

const app = createApp(App)
app.use(store)
app.use(router)
app.use(BootstrapVueNext)
app.use(LoadingPlugin)
app.component('AuthenticatedLayout', AuthenticatedLayout)
app.component('LoginLayout', LoginLayout)
app.mount('#app')
