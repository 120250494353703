import { createWebHistory, createRouter } from "vue-router";
import store from '../store'
import { IS_USER_AUTHENTICATED } from "../store/storeconstants";

  const routes =  [
    {path: "/login", component: () => import('../components/LoginView.vue'), meta: { layout: 'LoginLayout' },},
    {path: "/home", component: () => import('../components/WelcomeView.vue'), meta: { layout: 'AuthenticatedLayout' },},
    {path: "/test", component: () => import('../components/TestView.vue'), meta: { layout: 'AuthenticatedLayout' },},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: 'active'
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const isUserAuthenticated = store.getters[`auth/${IS_USER_AUTHENTICATED}`];
    console.log(isUserAuthenticated);

    if (authRequired && !isUserAuthenticated) {
        return '/login';
    }

    if (to.path == '/login' && isUserAuthenticated) {
        return '/home';
    }
});


export default router;