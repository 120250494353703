<template>
    <!-- HK Wrapper -->
    <div class="hk-wrapper hk-horizontal-nav hk-nav-toggle" style="height: 100%;">

        <!-- Top Navbar -->
        <nav class="navbar navbar-expand-xl navbar-light fixed-top hk-navbar">
            <a class="navbar-brand" href="#">
                <img class="brand-img d-inline-block" src="/images/cstuition-logo.png" alt="CSTuition Logo" style="max-height: 32px" />
                &nbsp; <span style="">CS Tuition Chat</span>
            </a>
            <ul class="navbar-nav hk-navbar-content">
                <b-dropdown class="btn-profile-dropdown">
                    <template #button-content>
                        <b-avatar src="/images/user-avatar.png" class="me-2"></b-avatar>
                        <span>{{ getUsername() }}</span>
                    </template>
                    <b-dropdown-item v-on:click="logout();">
                        <i class="dropdown-icon zmdi zmdi-power"></i><span>Log out</span>
                    </b-dropdown-item>
                </b-dropdown>
            </ul>
        </nav>
        <!-- /Top Navbar -->

        <!-- Main Content -->
        <slot></slot>
        <!-- /Main Content -->
    </div>
    <!-- /HK Wrapper -->
</template>
  
<script>
import { GET_USERNAME, SET_USERNAME, SET_AUTHENTICATION } from "../store/storeconstants";
import { CometChat } from "@cometchat-pro/chat";
import { useLoading } from 'vue-loading-overlay';

export default {
    methods:{
        getUsername() {
            return this.$store.getters[`auth/${GET_USERNAME}`]
        }, 
        logout() {
            let loading = useLoading();
            let loader = loading.show({
                container: null,
                canCancel: false,
            });

            CometChat.logout().then(
                () => {
                    console.log("Logout Cometchat completed successfully");
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userJson');
                    this.$store.commit(`auth/${SET_AUTHENTICATION}`, false);
                    this.$store.commit(`auth/${SET_USERNAME}`, "");
                    loader.hide();
                    this.$router.push('/login');
                },error=>{
                    loader.hide();
                    console.log("Logout Cometchat failed with exception:",{error});
                    alert('Fail to logout CometChat! Please try again later.');
                }
            );
        }
    },
    compatConfig: { MODE: 3 }
}
</script>

<style>
body {
    height: 100vh;
}

#app {
    height: 90vh;
}

.btn-profile-dropdown button {
    background-color: transparent !important;
    color: #000 !important;
    border: 0 !important;
}

.btn-profile-dropdown button:hover {
    color: #000 !important;
    background-color: transparent !important;
}
</style>
